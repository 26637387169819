<template>
  <div
    v-tooltip.bottom-end="team.name"
    draggable
    class="text-sm block text-gray-400 font-medium team-name-block"
  >
    <router-link
      :to="'/team/' + team.slug"
      class="workspace-switcher-item flex mx-2 my-4"
    >
      <TeamIcon
        :team="team"
        :is-active="isTeamActive"
      />
    </router-link>
  </div>
</template>
<script>
const TeamIcon = () => import('@components/team/TeamIcon')

export default {
  name: 'AppSidebarTeamSingle',

  components: {
    TeamIcon
  },

  props: {
    team: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showOpts: false
    }
  },

  computed: {

    isTeamActive () {
      if (this.activeTeamId === this.team.id) return true
      return false
    },

    activeProjectTeamId () {
      return this.activeProject.team_id
    },

    activeTeamId () {
      if (this.activeTeam === null) return null
      return this.activeTeam.id
    },

    activeTeam () {
      if (this.$route.path.startsWith('/team/')) return this.$store.getters.activeTeam
      return null
    },

    isActiveTeam () {
      if (this.activeTeam === null) return false
      return this.activeTeam.id === this.team.id
    }
  },

  methods: {

    bgClass () {
      return 'bg-gray-500'
      /*
        let rand = Math.floor( Math.random() * 7)

        switch (rand) {
          case 6:
            return 'bg-pink-500'
          case 5:
            return 'bg-indigo-500'
          case 4:
            return 'bg-teal-500'
          case 3:
            return 'bg-orange-500'
          case 2:
            return 'bg-blue-500'
          case 1:
            return 'bg-yellow-500'
          case 0:
            return 'bg-purple-500'
        }
        return 'bg-teal-500' */
    }
  }
}
</script>
